import Vue from 'vue'
import Router from 'vue-router'



const index = () => import('@/views/pages/index')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/index',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'index',
          name: 'Home Page',
          component: index
        }
      ]
    }
  ]
}

